.progress-bar-message {
   text-align: center;
   font-weight: 700;
   left: 48%;
   top: 45%;
   font-size: 1rem;
 }
 .progress-bar-percentage {
   font-weight: 700;
   font-size: 1.2rem;
   color: #067db8;
   padding-left: 5px;
 }
 