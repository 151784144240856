/* @require 'footer'
@require 'core'
@require 'navbar'
@require 'select'
@require 'ncss'
@require 'forms'
@require 'spinner'
@require 'variables'
@require 'markdown'
@require 'menu'
@require 'collapse'
@require 'alertify'
@require 'tooltip'
@require 'header'
@require 'donut'
@require 'grid'
@require 'dashboard'
@require 'progressTracker'  
@require 'trf'
@require 'modal'  
@require 'qrf'
@require 'testDetail'
@require 'list'
@require 'customTable'
@require 'datePicker'
@require 'frf' 
@require 'addMaterial'
@require 'addSupplier'
@require 'progressBar'
@require 'loader'
@require 'videoPlayer' 
@require 'toggle'
@require 'commentModal'
@require 'edit'
@require 'chip' */
html,
body,
#app-host {
  height: 100%;
  background-color: #fff;
  font-family: 'Open Sans Condensed', sans-serif;
}
body {
  -ms-overflow-style: scrollbar;
}
.viewport {
  height: 100vh;
}
.footer {
  position: fixed;
  z-index: 11;
  bottom: 0;
  width: 100%;
  margin-top: 85px;
}
.offline-section {
  padding: var(--card-padding);
}
.offline-section h2 {
  text-align: center;
  font-size: 4rem;
  text-transform: uppercase;
}
.text-color-accent {
  color: #0078b9;
}
.u-italic,
em {
  font-style: normal;
  color: #0078b9;
}
.button-top-adjust .u-uppercase {
  float: right;
}
.test_button {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: -1px;
  color: #fff;
  font-size: 13px;
  background: #3498db;
  border-radius: 2px 2px;
  line-height: 22px;
  margin-top: 2px;
  text-align: -webkit-center;
  text-align: center;
  font-weight: 700;
  background: rgba(52,152,219,0.9);
  color: #fff;
  font-size: 12px;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.test_button_proceed {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: -1px;
  color: #fff;
  font-size: 13px;
  background: #3498db;
  border-radius: 2px 2px;
  line-height: 22px;
  margin-top: 2px;
  text-align: -webkit-center;
  text-align: center;
  font-weight: 700;
  background: rgba(52,152,219,0.9);
  color: #fff;
  font-size: 12px;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.test_button_proceed_disabled {
  padding-right: 0px;
  padding-left: 0px;
  padding-top: -1px;
  color: #fff;
  font-size: 13px;
  background: #3498db;
  border-radius: 2px 2px;
  line-height: 22px;
  margin-top: 2px;
  text-align: -webkit-center;
  text-align: center;
  font-weight: 700;
  background: rgba(52,152,219,0.9);
  color: #fff;
  font-size: 12px;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  opacity: 0.6;
}
.main-heading {
  font-size: 2.8rem;
}
*:focus {
  outline: none;
}
.logout-page {
  margin-top: 6%;
  padding-bottom: 20px;
}
.clear-button-userpage {
  background: #3498db;
  padding: 0.31rem 2.5rem;
  border: 1px solid #3498db;
  margin-right: 10px;
  color: #fff;
}
.user-page {
  padding-left: 15px;
  font-size: 32px;
}
.pagenotFound {
  font-size: 32px;
  margin: 80px;
}
.usernotfound {
  margin: 40px;
  top: 24px;
  margin-right: 0px;
  width: 50%;
  padding: 30px;
}
.user {
  padding-top: 60px;
  padding-bottom: 10px;
}
.rc-progress-line {
  height: 11px;
  width: 100%;
}
.form {
  overflow-x: auto;
  scroll-behavior: smooth;
}
.container-horizontal-scroll {
  padding: 7px;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.text-area-container-sm .ncss-textarea {
  min-height: 5px;
}
.text-area-container-notification .ncss-textarea {
  min-height: 120px;
}
.invisible {
  display: none;
}
.padding-0 {
  padding: 0;
}
.archive-page-align-items {
  flex: 1 0 auto;
  margin: 55px 80px;
}
.rw-widget-picker {
  height: 40px;
}
.archive-search-area {
  display: flex;
  height: 60px;
}
.archive-search-area-height-adjust {
  height: 20px;
}
.archive-button {
  width: 12rem;
}
.archive-export {
  float: right;
}
.archive-export i {
  padding-right: 5px;
  font-size: 18px;
}
.archive-export-caption {
  padding: 0;
  letter-spacing: 0;
  font-family: unset;
  background: none;
  color: #fff;
}
.archive-export-button {
  font-size: 14px;
  text-align: right;
  font-weight: 700;
  float: right;
  padding: 4px;
  color: #fff;
  background: rgba(52,152,219,0.9);
  color: #fff;
  font-size: 12px;
  font-family: Nike TG, Helvetica Neue, Helvetica, Arial, sans-serif;
  padding-left: 10px;
  padding-right: 10px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .footer {
    position: sticky;
    bottom: 0;
    width: 100%;
  }
  body {
    overflow: hidden;
  }
  .progtrckr-component {
    margin-bottom: 10rem;
  }
  ol.progtrckr li span {
    display: -ms-inline-grid !important;
  }
  ol.progtrckr {
    padding-top: -1rem;
  }
  .progtrckr-component {
    top: 9rem;
  }
  .rw-select > * {
    height: 24px !important;
  }
  .inline-flex-display {
    display: inline-flex !important;
  }
  .ie-inline-display {
    display: inline-flex !important;
  }
  .menu-lightbox {
    position: relative;
  }
  .viewport {
    display: -ms-flexbox;
    flex-direction: column;
    position: absolute;
  }
  .pdfobject {
    z-index: -2 !important;
  }
  .pdfobject-container {
    height: 60vh !important;
  }
  .training_color .pdfobject-container {
    display: none;
  }
  .fake-iframe {
    display: block !important;
    width: 100%;
    z-index: -1;
  }
  .fake-iframe-footer-height {
    height: 85px;
  }
  .header-container {
    top: 0;
  }
  .fake-iframe-header-height {
    height: 45px;
  }
  .page-align-items {
    margin-top: 10px;
    margin-left: 80px;
    margin-right: 80px;
    padding-bottom: 2px;
  }
  .extra-bottom-margin {
    margin-bottom: 130px;
  }
  .download-button-trf {
    margin-bottom: 4%;
  }
  .ie-icon-align {
    position: relative;
    top: 9px;
  }
  .ie-border-padding-0-fix {
    padding: 0 !important;
  }
}
@supports (-ms-accelerator: true) {
  .footer {
    position: sticky;
    bottom: 0;
    width: 100%;
  }
  body {
    overflow: hidden;
  }
  .progtrckr-component {
    margin-bottom: 10rem;
  }
  ol.progtrckr li span {
    display: -ms-inline-grid !important;
  }
  ol.progtrckr {
    padding-top: 2rem;
  }
  .progtrckr-component {
    top: 11rem;
  }
  .rw-select > * {
    height: 24px !important;
  }
  .inline-flex-display {
    display: inline-flex !important;
  }
  .ie-inline-display {
    display: -ms-inline-flexbox !important;
  }
  .menu-lightbox {
    position: relative;
  }
  .viewport {
    display: -ms-flexbox;
    flex-direction: column;
    position: absolute;
  }
}
@supports (-ms-ime-align: auto) {
  .footer {
    position: sticky;
    bottom: 0;
    width: 100%;
  }
  body {
    overflow: hidden;
  }
  .progtrckr-component {
    margin-bottom: 10rem;
  }
  ol.progtrckr li span {
    display: -ms-inline-grid !important;
  }
  ol.progtrckr {
    padding-top: 2rem;
  }
  .progtrckr-component {
    top: 11rem;
  }
  .rw-select > * {
    height: 24px !important;
  }
  .inline-flex-display {
    display: inline-flex !important;
  }
  .ie-inline-display {
    display: -ms-inline-flexbox !important;
  }
  .menu-lightbox {
    position: relative;
  }
  .viewport {
    display: -ms-flexbox;
    flex-direction: column;
    position: absolute;
  }
}
.loading-dots:after {
  content: ' .';
  animation: dots 3s steps(1, end) infinite;
}
.training_page {
  font-size: 20px;
  padding-right: 0px;
}
.pdf {
  width: 100rem;
  height: 20rem;
}
.input-width {
  width: 100%;
}
.col-width-adjust {
  width: 3.7%;
}
.pdf-area {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}
.pdfobject-container {
  height: 75vh;
  padding-bottom: 10px;
}
@media screen and (min-height: 1001px) {
  .pdfobject-container {
    height: 65vh;
  }
}
.pdf-page-number {
  margin-bottom: 2%;
  font-weight: 600;
}
.pdf-page-bottom-align {
  margin-bottom: 10%;
}
.pdf-align-items {
  flex: 1 0 auto;
  margin: 55px 80px 80px 80px;
}
.video-align-items {
  flex: 1 0 auto;
  margin: 55px 80px 20px 80px;
}
.tab-class {
  padding-left: 0px !important;
}
.tab-class-bottom {
  padding-bottom: 0px !important;
}
.tab-class-container {
  margin-top: -11px;
  margin-left: 0px;
}
.fake-iframe {
  display: none;
}
.review_page * {
  vertical-align: top;
}
.expand {
  position: fixed;
  width: 100%;
  overflow: hidden;
  height: 3.65rem;
  background-color: #0078b9;
  padding-left: 100%;
  box-sizing: content-box;
  border: 2px solid #ddd;
  animation-timing-function: ease;
  animation-name: expand-dropdown;
  animation-duration: 1s;
  z-index: 9;
}
.expand .line {
  display: inline-block;
  height: 4rem;
  line-height: 3.4rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: expand-ticker;
  animation-duration: 30s;
}
.expand .line__item {
  display: inline-block;
  padding: 0.4 2rem;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}
.btn-times {
  position: fixed;
  z-index: 10;
  right: 0;
  height: 3.9rem;
  border: 2px solid #ddd;
  animation-timing-function: ease;
  animation-name: expand-dropdown;
  animation-duration: 1s;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 4px;
  line-height: 20px;
  max-height: 200px;
  display: inline-block;
  overflow: auto;
  background-color: #fff;
  border-color: #ccc !important;
  border-radius: 4px !important;
  border-style: solid;
  border-width: 1px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  content: "\f078" !important;
  vertical-align: middle;
  font-family: "Font Awesome 5 Free";
  float: right;
  margin-top: 6px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  content: "\f077" !important;
  vertical-align: middle;
  font-family: "Font Awesome 5 Free";
  float: right;
  margin-top: 6px;
}
.react-dropdown-tree-select .dropdown,
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
  width: 100%;
}
.react-dropdown-tree-select .dropdown .dropdown-content {
  width: 100%;
  bottom: 100%;
  margin-bottom: 4px;
  position:  relative !important;
}
.restrict-user {
  margin-top: 50px;
}
@-moz-keyframes dots {
  0%, 12.5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  37.5% {
    text-shadow: 0.5em 0;
  }
  50% {
    text-shadow: 0.5em 0, 1em 0;
  }
  62.5% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0;
  }
  75% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0;
  }
  87.5%, 100% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
  }
}
@-webkit-keyframes dots {
  0%, 12.5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  37.5% {
    text-shadow: 0.5em 0;
  }
  50% {
    text-shadow: 0.5em 0, 1em 0;
  }
  62.5% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0;
  }
  75% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0;
  }
  87.5%, 100% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
  }
}
@-o-keyframes dots {
  0%, 12.5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  37.5% {
    text-shadow: 0.5em 0;
  }
  50% {
    text-shadow: 0.5em 0, 1em 0;
  }
  62.5% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0;
  }
  75% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0;
  }
  87.5%, 100% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
  }
}
@keyframes dots {
  0%, 12.5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  37.5% {
    text-shadow: 0.5em 0;
  }
  50% {
    text-shadow: 0.5em 0, 1em 0;
  }
  62.5% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0;
  }
  75% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0;
  }
  87.5%, 100% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
  }
}
@-moz-keyframes expand-ticker {
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes expand-ticker {
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@-o-keyframes expand-ticker {
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes expand-ticker {
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@-moz-keyframes expand-dropdown {
  from {
    transform: translateY(calc(-100vh));
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes expand-dropdown {
  from {
    transform: translateY(calc(-100vh));
  }
  to {
    transform: translateY(0);
  }
}
@-o-keyframes expand-dropdown {
  from {
    transform: translateY(calc(-100vh));
  }
  to {
    transform: translateY(0);
  }
}
@keyframes expand-dropdown {
  from {
    transform: translateY(calc(-100vh));
  }
  to {
    transform: translateY(0);
  }
}
.chip {
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 30px;
  color: rgba(0,0,0,0.8);
  background-color: #e6e6e6;
  margin-right: 4px;
  margin-bottom: 4px;
}

.progress-wrapper {
  height: 100%;
  background: gray;
  border-radius: 6px;
  display: flex;
}
.progress-bar-po {
  background: rgba(52,152,219,0.9);
  height: 100%;
  width: 49%;
  color: white;
  border-radius: 6px;
}
.progress-text {
  position: absolute;
    color: white;
    width: -webkit-fill-available;
    text-align: center;
}
