.ag-theme-balham .ag-header {
    border-bottom: 0;
  }
  .ag-theme-balham .ag-header .ag-header-row:nth-child(2) {
    background-color: #ddd;
  }
  .ag-theme-balham .ag-header .ag-header-row {
    color: var(--ncss-black);
    font-weight: bold;
    font-size: 14px;
  }
  .ag-theme-balham .ag-header .ag-header-cell::after,
  .ag-theme-balham .ag-header .ag-header-group-cell::after {
    border-right: none;
  }
  .ag-theme-balham .ag-row {
    border-color: transparent;
  }
  .ag-theme-balham .ag-row .ag-cell {
    font-weight: bold;
    font-size: 14px;
    color: #707070;
    border-color: transparent;
  }
  .ag-theme-balham .ag-row-odd {
    background-color: #f5f5f5;
  }
  .ag-theme-balham .ag-row-selected {
    background: #ccebff;
  }
  .ag-theme-balham .ag-paging-panel {
    justify-content: space-between;
    margin-right: 16px;
    padding: 0px;
    border: none;
    color: #000;
  }
  .ag-theme-balham .ag-root {
    border: none;
    border-top: 1px solid #bdc3c7;
  }
  .ag-theme-balham .ag-side-bar {
    border: 1px solid #bdc3c7;
  }
  .ag-theme-balham .ag-status-bar {
    border: none;
  }
  .ag-cell-value, .ag-group-value {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 14px;
    color: #707070;
    border-color: transparent;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
  
  .alert-icon {
    color: var(--ncss-error);
  }
  .ag-theme-balham .ag-header-cell-label {
    width: 100%;
  }
  .ag-theme-balham .ag-row {
    border-color: #d9dcde;
  }
  .span-width {
    margin-left: 0px;
  }
  .custom-height {
    overflow: hidden;
    height: 18rem;
  }
  @media screen and (max-height: 714px) and (min-height: 700px) {
    .custom-height {
      height: 19rem;
    }
  }
  @media screen and (max-height: 750px) and (min-height: 715px) {
    .custom-height {
      height: 20rem;
    }
  }
  @media screen and (max-height: 799px) and (min-height: 751px) {
    .custom-height {
      height: 23rem;
    }
  }
  @media screen and (max-height: 850px) and (min-height: 800px) {
    .custom-height {
      height: 26rem;
    }
  }
  @media screen and (max-height: 900px) and (min-height: 851px) {
    .custom-height {
      height: 29rem;
    }
  }
  @media screen and (max-height: 1000px) and (min-height: 901px) {
    .custom-height {
      height: 30rem;
    }
  }
  @media screen and (max-height: 1200px) and (min-height: 1001px) {
    .custom-height {
      height: 31rem;
    }
  }
  @media screen and (min-height: 1201px) {
    .custom-height {
      height: 32rem;
    }
  }
  .test-detail-height {
    height: 15rem;
  }
  .ag-row-no-animation .ag-row {
    transition: none;
  }
  .ag-header-cell-label {
    text-overflow: clip;
    overflow: visible;
    white-space: normal;
  }
  .ag-theme-balham .ag-header-cell-label .ag-header-cell-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-height-lg {
    height: 31rem;
  }
  .custom-height-material {
    height: 100% !important;
    overflow-x: hidden !important;
  }
  /*upload grid */
  .upload-height {
    height: 28rem;
    margin-bottom: 2rem;
  }
  /*po grid*/
  .po-height {
    height: 21rem;
    margin-bottom: 2rem;
    min-height: calc(68vh - 120px);
  }

  /*po grid*/
  .idLocker-stepper-grid-height {
    height: 280px !important;
  }
  .custom-height-material .ag-body-viewport {
    overflow-x: hidden;
  }
  .custom-height-material .ag-body-horizontal-scroll-viewport {
    overflow-x: hidden;
  }
  .custom-height-material .ag-center-cols-viewport {
    overflow-x: hidden;
  }
  .ag-hide-toolpanel {
    display: none;
  }
  .infinite-grid .ag-header {
    height: 60px !important;
  }
  .ag-horizontal-left-spacer,
  .ag-horizontal-right-spacer {
    overflow-x: hidden;
  }
  .flat-picker-filter {
    margin: 2%;
    width: 100%;
  }
  .grid_comments_button {
    margin: 0 10px;
    padding-top: -1px;
    color: #fff;
    font-size: 13px;
    background: #3498db;
    border-radius: 2px 2px;
    line-height: 22px;
    margin-top: 2px;
    text-align: -webkit-center;
    text-align: center;
    font-weight: 700;
    background: rgba(52,152,219,0.9);
    color: #fff;
    font-size: 13px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .custom-date-filter {
      width: 80%;
    }
  }
  .flatpickr-weekdays,
  .flatpickr-months,
  .flatpickr-month,
  span.flatpickr-weekday,
  .flatpickr-current-month,
  .flatpickr-monthDropdown-months,
  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: #3498db !important;
  }

  .cell-content-center {
    justify-content: center;
    display: flex;
    color: #707070;
  }
  
  .custom-header-component {
    display: inline-flex !important;
  }

  .custom-header-checkbox {
    padding-right: 4px;
  }