.add-supplier-location-buttton {
  width: 13rem;
  border: 0px;
}
.add-supplier-search {
  width: 11rem !important;
}
.shipment-count{
  font-size: 17px;
  float: right;
  color: #6d6d6d;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
.shipment-count .sub-count{
  border: 0.5px solid #b1acac;
  font-weight: 700;  
  color: #0078b9;
  padding: 5px;
  border-radius: 10px;
}
.user_details {
  border: 2px solid #ddd;
  padding: 10px;
  margin: 10px;
  overflow: visible;
}
.user_details_admin {
  border: 2px solid #ddd;
  padding: 10px;
  margin: 10px;
  overflow: visible;
  min-height: 320px;
}
.preview_details {
  border: 2px solid #ddd;
  margin: 2px;
  overflow: visible;
}
.adj-content {
  font-size: 16px;
}
.adj-select-user .eds-select .eds-select__control {
  opacity: 1;
  background: #fff;
  /* height: 50px; */
}
.adj-select-font .eds-select .eds-select__placeholder {
  font-weight: normal;
}
.adj-dropdown-user {
  overflow: visible;
  width: 100%;
}
.client-id-fields {
  margin: 12px 0;
}
.client-id-table {
  margin: 12px 16px 0px 12px;
  padding-left: 10px;
}

.edit-icon-client {
  margin: 60px 0px 0px 0px;
  cursor: pointer;
}

.add-client-container {
padding: 4px;
}

.vendor-group-toggle {
  font-size: 16px;
  width: auto;
}
.vendor-group-field {
  margin-bottom: 24px;
}
.edit-vendor-disabled-fields input {
  background: lightGray;
}
client-id-fields.user_details {
  padding: 0 8px;
}
.add-supplier-search-color {
  background: #111;
}
.full-width {
  width: 100%;
}
.supplier-add-new-button {
  background-color: #fff;
  background: #ddd;
  border: 1px solid #ddd;
}
.active-supplier-button {
  color: #0078b9;
  background: #fff;
  border-bottom: 5px solid #0078b9;
}
.not-active-supplier-button {
  color: #000;
  border: 1px solid #ddd;
  color: #000;
  background: #ddd;
  opacity: 0.5;
}
.extra-bottom-margin {
  margin-bottom: 100px;
}
.adjust-padding {
  padding-left: 75px !important;
}
.adjust-location-text-padding {
  padding-left: 50px;
}
.span_addsupplier {
  height: 1vh;
}
.btn-separater {
  margin-right: 8px;
  vertical-align: -webkit-baseline-middle;
}
.supplier_class {
  padding: 0;
  padding-bottom: 18px;
}
.supplier_class-tab {
  padding: 0;
  padding-bottom: 0px;
}
.dropdown-add-supplier .select-input-font,
.dropdown-add-locations .select-input-font {
  font-size: 12px !important;
  font-weight: bold !important;
}
.mandatory_supplier {
  color: #f00;
}
.update-exception-buttton {
  width: 15rem;
  border: 0px;
}
.update-notification-buttton {
  border: 0px;
  padding: 0.45rem 2.4rem;
}
.edit-user-modal-container {
  width: 65% !important;
  top: 45% !important;
  max-height: 90%;
  overflow-y: scroll;
}
.edit-vendor-group-modal-container {
  width: 72% !important;
  top: 45% !important;
  max-height: 90%;
  overflow-y: scroll;
}
.add-vendor-group-modal-container {
  width: 72% !important;
  top: 45% !important;
  max-height: 90%;
  overflow-y: scroll;
}
.text_edit {
  color: #707070;
  font-weight: 700;
  font-size: 18px;
  margin-left: 12%;
}
.edit-mapping-modal-container {
  width: 90% !important;
  top: 45% !important;
  max-height: 90%;
  overflow-y: scroll;
}
.add-supplier-modal-container {
  width: 80% !important;
}
.grid-filter-textbox-licensee {
  border: 2px solid #3498db;
  width: 100%;
  height: 2.5rem;
  padding: 15px;
  color: #000;
  font-size: 13px;
  font-weight: bold;
}
.grid-height-adjust {
  height: 18rem;
}
.update-group-name-section {
  margin-bottom: 24px;
}
.eds-tooltip__anchor {
  color: #0078b9;
  vertical-align: top;
}

.user_section {
  color: #0078b9;
  font-size: 18px;
}