.react-calendar {
  width: 17rem !important;
  max-width: 100% !important;
  z-index: 3;
  position: relative !important;
  background-color: #fff !important;
  border: 1px solid #000;
}
.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
  font-weight: 900;
}
.react-calendar__navigation {
  border-bottom: 1px solid #a0a096;
}
.react-calendar__tile--active {
  background: #3498db;
  font-weight: 900;
}
.react-date-picker__wrapper {
  display: flex;
}
.react-date-picker__inputGroup {
  max-width: 20%;
  border: thin solid #808080;
  flex-grow: 1;
  display: flex;
  padding: 0 2px;
  align-items: baseline;
  box-sizing: content-box;
}
.react-calendar__decade-view {
  padding: 0.5rem;
}
.react-calendar__tile {
  padding: 0.8em 0.3em !important;
  margin-bottom: 0.3rem !important;
}
.react-date-picker__calendar {
  width: 60%;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__clear-button {
  display: none;
}
.react-date-picker__inputGroup__input {
  text-align: center;
  width: 100% !important;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
}
.react-date-picker__calendar-button {
  border: thin solid #808080;
  padding: 5px 4px 0px 4px !important;
}
.react-calendar__tile {
  border: 1px solid #000;
}
.react-calendar__month-view__weekdays__weekday {
  text-align: center;
}
.react-date-picker__calendar--open {
  position: relative !important;
  min-height: 250px !important;
}
.rdtPicker {
  position: relative !important;
  border: 1px solid #000;
}
.rdt .form-control {
  height: 30px;
  border: solid 1px #000;
  text-align: center;
  font-weight: 600;
  width: 25%;
  border-radius: 0;
}
.calender-input {
  position: relative;
}
.rdt .calender-icon {
  font-size: 17px;
  padding: 1px 3px 4px 3px;
  border: solid 1px #000;
}
.rdtYear .rdtDisabled {
  display: none;
}
.rdtYear {
  border: 1px solid #000;
  padding: 0.8em 0.3em !important;
  margin-bottom: 0.3rem !important;
  background-color: #eae8e8;
}
.rdtSwitch,
.rdtPrev,
.rdtNext {
  background-color: #f0f0f0;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: text-top;
}
.rdtPicker th {
  border-bottom: 5px solid #f9f9f9;
}
