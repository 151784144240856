/* @require 'variables' */
.dashboard .status-area {
    vertical-align: top;
  }
  .dashboard .status-box {
    padding: 1rem 0;
    position: relative;
    background-image: url("nike-img.avif");
    height: auto;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
  }
  .dashboard .admin-bg-img {
    min-height: calc(95vh - 23px);
  }
  .dashboard .partner-bg-img {
    min-height: calc(100vh - 300px);
  }
  .dashboard .status-box-mapping {
    padding: 1rem 0;
    position: relative;
    background-image: url("nike.png");
    height: 200px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
  }
  .dashboard .status-box-mapping .progress_bar {
    position: absolute;
    width: 100%;
    top: 0;
  }
  .dashboard .mapping-screen-img {
    background-size: 50% 150%;
    margin-right: 0px;
    background-position: right;
  }
  .dashboard .chart-area {
    border-left: 1px solid var(--ncss-grey);
    padding: 0 5rem;
    display: none;
  }
  .dashboard .p0-top-sm {
    padding: 0 0px 0px 0px;
  }
  .dashboard .m0-sm {
    margin: 0;
    box-shadow: 5px 5px 5px #b1acac;
    border: 1px solid #cacfd2;
  }
  .dashboard .lab {
    margin-left: -36px;
    margin-right: -34px;
  }
  .dashboard .nike-icon {
    font-size: 25px;
  }
  .dashboard .converse-icon {
    font-size: 15px;
  }
  .dashboard .hurley-icon {
    font-size: 18px;
  }
  .dashboard .save-history-btn {
    float: right;
    font-weight: 600;
    background: rgba(52,152,219,0.9);
    color: #fff;
    font-size: 14px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 10px 0px 10px;
  }
  .dashboard .time-duration-btn {
    float: left;
    font-weight: 600;
    color: #000;
    font-size: 12px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0 5px 0px 5px;
    cursor: pointer;
  }
  .dashboard .time-duration-btn-text {
    float: left;
    font-weight: 600;
    color: #000;
    font-size: 12px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0 5px 0px 5px;
  }
  .dashboard .active-time-duration-btn {
    float: left;
    font-weight: 600;
    background: rgba(52,152,219,0.9);
    color: #fff;
    font-size: 12px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
  }
  .dashboard .dashboard-card {
    width: 250px;
    overflow: hidden;
    display: inline-table;
  }
  /* .dashboard .dashboard-card:hover {
    min-height: 100%;
  } */
  .dashboard .dashboard-card-sub {
    height: 95px;
    overflow: hidden;
  }
  .dashboard .dashboard-card-sub-card {
    height: 110px;
    overflow: hidden;
  }
  .dashboard .dashboard-card-sub-upload {
    height: 90px;
    overflow: hidden;
  }
  .dashboard .dashboard-card-sub:hover {
    min-height: 170px;
  }
  .dashboard .dashboard-card-sub-upload:hover {
    min-height: 100%;
    height: 100%;
  }
  .dashboard .dashboard-card-sub-card:hover {
    min-height: 100%;
    height: 100%;
  }
  .dashboard .status-margin {
    margin-top: 15px;
  }
  .dashboard .background-status {
    padding: 20px;
    background: rgba(221,221,221,0.8);
    margin: 4px;
    box-shadow: 5px 5px 5px #b1acac;
  }
  .dashboard .eds-link {
    text-decoration: none;
  }
  .dashboard .background-status-box {
    padding: 20px 0 20px 0;
    background: #fff;
    padding: 7px;
    margin: 7px;
    border-radius: 0px;
    box-shadow: 5px 5px 15px #b1acac;
    /* height: 100%; */
  }
  .dashboard .background-status-box-status {
    padding: 15px;
    margin: 15px;
    border-radius: 0px;
    box-shadow: 5px 5px 15px #b1acac;
    background: #ff8255;
    color: #fff;
    min-height: 50px;
    min-width: 181px;
  }
  .dashboard .background-status-box-map {
    padding: 15px;
    margin: 15px;
    border-radius: 0px;
    box-shadow: 5px 5px 15px #b1acac;
    background: #ff8255;
    color: #fff;
    min-height: 50px;
    min-width: 191px;
  }
  .dashboard .background-status-box::hover {
    background: rgba(170,170,170,0.8);
    color: #f00;
    cursor: pointer;
  }
  .dashboard .background-status-box-status::hover {
    background: #ff0 !important;
    z-index: 100000;
  }
  .dashboard .text-color-orange {
    color: #a70e00;
  }
  .dashboard .background-status-selected {
    padding: 20px 0 20px 0;
    background: #ddd;
    padding: 10px 20px 10px 20px;
    background: #aaa;
    margin: 10px;
    box-shadow: 5px 5px 5px #b1acac;
  }
  .dashboard .background-status:hover {
    background: rgba(170,170,170,0.8);
    color: #fff;
    cursor: pointer;
  }
  .dashboard .save-history-btn:disabled {
    float: right;
    font-weight: 600;
    background-color: var(--ncss-accent);
    color: var(--ncss-white);
    font-size: 14px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 10px 0px 10px;
    cursor: not-allowed;
    pointer-events: none;
  }
  .dashboard .filter-class {
    padding: 5px 0 4px 0;
    background: #ddd;
  }
  .dashboard .ag-side-bar .ag-side-buttons div button span {
    color: #3498db;
    font-weight: bold;
    font-size: 13px;
  }
  .dashboard .ag-theme-balham .ag-side-bar .ag-side-buttons .ag-side-button button {
    border-color: #ddd;
  }
  .dashboard .ag-theme-balham .ag-tab-body {
    overflow: scroll;
    padding-bottom: 30px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    margin-top: 3px;
    width: 6px;
    background: rgba(0,0,0,0.1);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(0,0,0,0.3);
    box-shadow: 0 0 1px rgba(255,255,255,0.5);
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ag-side-bar .ag-side-buttons button {
      float: right;
    }
    .ag-side-bar .ag-side-buttons div button .ag-icon-columns {
      position: relative;
      right: -16px;
      top: -3px;
    }
    .ag-side-bar .ag-side-buttons div button .ag-icon-filter {
      position: relative;
      right: -7px;
      top: -5px;
    }
    .ag-body-viewport-wrapper.ag-layout-normal {
      overflow-x: hidden !important;
    }
  }
  @supports (-ms-ime-align: auto) and (font-variation-settings: normal) {
    .ag-body-viewport-wrapper.ag-layout-normal {
      overflow-x: hidden !important;
    }
  }

.dashboard-grid {
  display: flex;
  justify-content: space-around;
}
  