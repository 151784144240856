.bg {
  background-color: #ddd;
  margin-top: 3%;
  padding-bottom: 2%;
}
.bg-po {
  background-color: #ddd;
}
.users {
  padding-bottom: 1%;
}
.page-align {
  flex: 1 0 auto;
  margin: 30px 40px 20px;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
