.chip {
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 30px;
  color: rgba(0,0,0,0.8);
  background-color: #e6e6e6;
  margin-right: 4px;
  margin-bottom: 4px;
}
.closebtn {
  padding-left: 10px;
  color: rgba(0,0,0,0.8);
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}
.closebtn:hover {
  color: #d3dae1;
}
.chip-input {
  min-height: 48px;
  padding: 5px;
  background: #fff;
  font-size: 16px;
  width: 75%;
  display: inline-block;
}
