/* @require 'variables' */
.page-header {
  box-shadow: box-shadow-divider;
  color: var(--ncss-black);
  margin-bottom: 1rem;
}
.page-header .ncss-container {
  display: flex;
}
.page-header h1 {
  padding-top: 2.4rem;
  padding-bottom: 1.5rem;
  display: inline-block;
  flex: 1 0 auto;
}
.page-sub-header h1,
.page-sub-header h2 {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}
.section-header .section-header-item {
  display: inline-block;
  vertical-align: middle;
}
