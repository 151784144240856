/* @require 'variables' */

body {
  background-color: var(--background-color);
}
.link {
  text-decoration: underline;
}
.u-va-tb {
  vertical-align: text-bottom;
}
.hidden {
  display: none;
}
.invisible {
  opacity: 0;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.pointer-mouse {
  cursor: pointer;
}
.relative-container {
  position: relative;
}
ul.list-unstyled {
  list-style-type: none;
}
ul.horizontal li {
  display: inline-block;
  list-style-type: none;
}
.page-header h1.page-title-has-back {
  position: relative;
  margin: 0.5rem 0;
}
.page-header h1.page-title-has-back .page-title-back {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  font-size: 0.8rem;
  color: var(--ncss-accent);
}
.page-header .ncss-flyout-btn {
  padding: 2px 40px 2px 20px;
}
.page-sub-header h1,
.page-sub-header h2 {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}
.section-header .section-header-item {
  display: inline-block;
  vertical-align: middle;
}
.u-rotate-right {
  transform: rotate(90deg);
}
.u-rotate-left {
  transform: rotate(270deg);
}
.ncss-dl dt {
  font-weight: 600;
}
.ncss-dl dd {
  padding: 0 0 0.5rem 0;
}
.ncss-dl-inline dt,
.ncss-dl-inline dd {
  display: inline;
  margin: 0;
}
.ncss-dl-inline dt {
  font-weight: 600;
}
.ncss-dl-inline dd {
  padding: 0 0 0.5rem 0.5rem;
}
.ncss-dl-inline dd:after {
  content: '\A';
  white-space: pre;
}
.selectable-highlight {
  padding: 0.5rem 1rem;
  background-color: var(--ncss-white);
  border: 1px solid var(--ncss-success);
  display: inline-block;
  font-weight: bold;
  color: var(--ncss-grey);
  overflow: auto;
}
.u-nowrap {
  white-space: nowrap;
}
.flex-container {
  display: flex;
  align-items: flex-end;
}
.flex-container.start {
  align-items: start;
}
.flex-container.center {
  align-items: center;
  justify-content: center;
}
.flex-fill {
  padding-top: 10px;
  flex: 0 0 auto;
}
.flex-fluid {
  flex: 1 1 auto;
}
.flex-static {
  flex: 0 0 auto;
}
.u-half-width {
  width: 50%;
}
@media only screen and (min-width: var(--min-break-md)) {
  .u-half-width-md {
    width: 50%;
  }
}
@media only screen and (min-width: var(--min-break-lg)) {
  .u-half-width-lg {
    width: 50%;
  }
}
@media only screen and (min-width: var(--min-break-xl)) {
  .u-half-width-xl {
    width: 50%;
  }
}
@media only screen and (min-width: var(--min-break-xxl)) {
  .u-half-width-xl .u-half-width-xxl {
    width: 50%;
  }
}
.card-container {
  padding: var(--card-padding);
  background-color: var(--ncss-white);
  box-shadow: var(--box-shadow-divider);
}
.circle {
  border-radius: 50%;
  padding: 1px 3px;
}
.page-header,
h1.page-title-has-back {
  position: relative;
  margin: 1rem 0;
}
.rounded {
  border-radius: 25%;
}
.align-center {
  text-align: center;
}
.align-text-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.big-button {
  font-size: 30px;
  background-color: var(--ncss-white);
  border: 1px solid var(--ncss-grey);
}
.big-button:focus {
  outline: 0;
}
.big-button-selected {
  font-size: 30px;
  background-color: var(--ncss-accent);
  border: 1px solid var(--ncss-accent);
  box-shadow: 5px 5px 5px #b1acac;
}
.big-button-selected:focus {
  outline: 0;
}
.icon-button-margin {
  margin: 5px !important;
}
.button-top-adjust {
  position: relative;
  top: 0.5rem;
}
.sub-heading {
  font-size: 25px;
}
.sub-header {
  font-size: 16px;
  color: #000;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page-header h1.page-title-has-back {
    position: relative;
    margin: 0 0;
  }
  .ag-side-bar .ag-side-buttons div button span {
    transform: rotate(90deg);
    margin-left: -15px;
    float: right;
    position: relative;
    right: -13px;
  }
  .ag-theme-balham .ag-icon-filter {
    margin-left: 3px !important;
    padding-top: 20px;
  }
  .ag-theme-balham .ag-icon-columns {
    margin-left: 3px !important;
    padding-top: 35px;
  }
  .ag-theme-balham .ag-side-bar .ag-side-buttons .ag-side-button button {
    min-height: 100px;
  }
  .ag-theme-balham .ag-side-bar .ag-side-buttons .ag-selected button {
    width: 100%;
  }
  .dashboard .ag-theme-balham .ag-side-bar .ag-side-buttons .ag-side-button button {
    padding: 22px 0;
  }
}
.page-header-dashboard {
  position: relative;
  margin: 0.5rem 0;
}
.page-header-dashboard,
h1.page-title-has-back {
  position: relative;
  margin: 1rem 0;
  margin-top: 31px;
  font-family: var(--eds-type-nike-futura);
}
.page-header-dashboard-upload {
  font-family: var(--eds-type-nike-futura);
  color: #0078b9;
  background-image: url("nike.png");
  height: 50px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 120px;
  width: 100%;
}
.eds-select .eds-select__control {
  background: #f1f1f1;
  opacity: 0.8;
}
.eds-select .eds-select__placeholder {
  color: #000;
  font-weight: 600;
}
.eds-type--title-6 {
  font-weight: 700 !important;
  color: #6d6d6d;
}
.vertical-top {
  padding: 10px 20px 10px 20px;
  background: rgba(221,221,221,0.8);
  margin: 10px;
  box-shadow: 5px 5px 5px #b1acac;
}
