@keyframes spinner {
    to {transform: rotate(360deg)}
}

@-webkit-keyframes spinner {
    to {-webkit-transform: rotate(360deg)}
}

/* --spinner-sm: 1rem */
/* --spinner-lg: 10rem */

.spinner:before {
  content: 'Loading…';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
}
.spinner.spinner-lg:before{
  width: 10rem;
  height: 10rem;
  margin-top: -5rem;
  margin-left: -5rem;
}
.spinner:not(:required):before{
  content: '';
  border-radius: 50%;
  border-top: 4px solid #3498db;
  border-right: 1px solid transparent;
  animation: spinner .6s linear infinite;
  -webkit-animation: spinner .6s linear infinite;
}
.modal-overlay-spinner{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 15;
}
.map-overlay-spinner{
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 10;
}
  .spinner-lg-text{
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 24px;
      font-weight: bold;
      color: #3498db;
      transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
}


