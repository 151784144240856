@require 'variables'
.donut .title {
  font-weight: 900;
}
.donut .legend-label {
  font-weight: 700;
  font-size: 20px;
  fill: #8d8d8d;
}
.donut .legend-label-lg {
  font-weight: 700;
  font-size: 29px;
}
.donut .center-text {
  font-weight: 800;
  font-size: 21px;
}
