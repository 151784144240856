/* @require 'variables' */

/* nav-button-padding = 56px */
.menu-container {
  display: flex;
  width: 100%;
  height: 100%;
}
.menu-lightbox {
  opacity: 0;
  visibility: hidden;
  background: rgba(0,0,0,0);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1001;
  transition: opacity 300ms ease;
}
.show-menu .menu-lightbox {
  opacity: 1;
  visibility: visible;
  background: rgba(0,0,0,0.4);
}
.menu-logo {
  font-size: 22px;
  vertical-align: baseline;
  padding-right: 10px;
  display: none;
}
.menu-aside {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  width: 300px;
  height: 100%;
  z-index: 10001;
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 300ms ease;
}
.eds-vertical-navbar {
  position: fixed;
  z-index: 11;
}
.eds-vertical-navbar .nav {
  width: 100%;
  height: auto;
  flex: 0 0 auto;
  overflow-y: auto;
  padding-bottom: 120px;
  background: #ddd;
}
.eds-vertical-navbar .nav ul li {
  margin: 9px;
  background: #f7f7f7;
  margin: 5px 10px 30px 5px;
  font-size: 20px;
}
.eds-vertical-navbar .nav .box-shadow {
  box-shadow: 5px 5px 10px 5px;
}
.eds-vertical-navbar .footer {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.eds-vertical-navbar .footer .footer-spacer {
  display: block;
  flex: 1 1 0;
}
.eds-vertical-navbar .footer span {
  margin: 0 auto;
  flex: 0 0 auto;
  display: flex;
}
.show-menu .menu-aside {
  transform: translateX(0);
  background: #ddd;
}
.viewport {
  display: flex;
  flex-direction: column;
  will-change: transform;
  width: 100%;
  overflow: auto;
  background: #f7f7f7;
}
.slide-view-port {
  will-change: initial;
  padding-left: 102px;
}
.menu-btn {
  position: fixed;
  top: 8px;
  left: 8px;
}
.profile-nav {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profile-nav .profile-nav-item {
  flex: 0 0 auto;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}
.profile-nav .profile-nav-item span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}
.profile-nav.profile-nav-item {
  flex: 1 1 auto;
}
.profile-nav .user-info {
  flex: 1 1 auto;
}
.badge-notification {
  position: absolute;
  right: -8px;
  top: -8px;
  font-size: 1rem;
  background-color: #f00;
  color: var(--ncss-white);
  display: inline;
  line-height: 0.8rem;
  padding: 3px 5px;
}
.menu-header {
  text-align: left;
  padding: 20px;
  background: #f7f7f7;
  padding-left: 40px;
  border: 2px solid #a0a0a0;
}
.seperation-line {
  color: var(-ncss-grey-light);
  margin: 25px 0px 25px 0px;
}
.li-bg-color {
  padding: 10px;
  font-family: "Open Sans Condensed", sans-serif;
}
.menu-icons-nav {
  width: 7%;
  padding: 15px;
  padding-right: 20px;
}
