.comments-heading {
    font-size: 15px;
    font-weight: 600;
  }
  .comments-info-section {
    background-color: #fff;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    margin-right: 10px;
  }
  .comments-text {
    padding: 4px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    background-color: #3498db;
    border: 1px solid #3498db;
    vertical-align: top;
  }
  .display-comments-area {
    overflow-y: auto;
    height: 63%;
  }
  .comment-section {
    display: flex-root;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 100%;
  }
  .add-show-comments-height {
    height: 63%;
  }
  .add-display-comments-height {
    height: 78%;
  }
  .add-comment-height {
    height: 25%;
  }
  .display-comment-height {
    height: 10%;
  }
  .comment-textarea {
    width: 100%;
    min-height: 100px;
    margin: 0 10px;
    border: 2px solid #707070;
    font-size: 16px;
    padding: 10px;
  }
  .chart-view-popup {
    padding: 0;
  }
  .outer-box {
    border: 1px solid #ddd;
    box-shadow: 5px 5px 5px #b1acac;
    padding: 10px;
    margin: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .update-comment-button:disabled {
    border: 1px solid #999;
    background-color: #ccc;
    color: #666;
    margin-right: 10px;
  }
  