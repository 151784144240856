@require 'variables'
.markdown-container ul {
  list-style: disc inside;
}
.markdown-container ol {
  list-style: decimal inside;
}
.markdown-container ul ul,
.markdown-container ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}
.markdown-container ol ol,
.markdown-container ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}
.markdown-container a {
  color: var(--ncss-link-color);
  text-decoration: underline;
}
.markdown-container h1,
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.markdown-container h4 {
  font-size: 22px;
}
.markdown-container h5 {
  font-size: 18px;
}
.markdown-container img {
  margin: 0.5rem 0;
}
.markdown-container code {
  background-color: var(--ncss-offwhite);
  color: #d04670;
  padding: 2px 4px;
}
.markdown-container pre code {
  background-color: var(--ncss-offwhite);
  display: inline-block;
}
.tooltip-markdown a {
  color: var(--ncss-white);
  text-decoration: underline;
}
.tooltip-markdown .markdown-container code {
  background-color: #888;
  color: var(--ncss-white);
  font-family: Consolas, monaco, monospace;
}
