/* @require 'variables' */
.rTable {
    display: table;
  }
  .rTableRow {
    display: table-row;
    text-align: center;
  }
  .rTableHeading {
    display: table-header-group;
  }
  .rTableBody {
    display: table-row-group;
  }
  .rTableFoot {
    display: table-footer-group;
  }
  .rTableCell,
  .rTableHead {
    display: table-cell;
  }
  