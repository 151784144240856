.TestDetails {
    margin-bottom: 88px;
  }
  .TestDetails .test-detail-heading {
    font-size: 2rem;
  }
  .TestDetails .test-inline {
    display: inline;
  }
  .TestDetails .test-padding {
    padding-left: 101px;
  }
  .TestDetails .detail {
    margin: 0.8rem;
  }
  .TestDetails .m0-bottom-sm {
    margin-bottom: 10px;
  }
  .TestDetails .collapsible {
    padding-bottom: 10px;
  }
  .TestDetails .color-rd {
    color: #fe0000;
  }
  .TestDetails .color-or {
    color: #ea723f;
  }
  .TestDetails .color-bl {
    color: #000;
  }
  .TestDetails .icon {
    padding: 1px 5px;
    border-radius: 50%;
    position: relative;
    top: 10px;
    left: 7%;
    display: inline-block;
  }
  .TestDetails .big-button-color {
    border: 1px solid #e5e5e5;
    font-size: 17px;
    top: -5px;
    left: 1%;
    background: #fe0000;
  }
  .TestDetails .success-section {
    padding: 0.5rem 1.5rem;
    background: #f5f5f5;
  }
  .TestDetails .success-font {
    font-size: 1.3rem;
  }
  .TestDetails .download-print-section {
    font-size: 14px;
    background: #f7f7f7;
    text-align: right;
    font-weight: bold;
    float: right;
    padding-right: 17px;
  }
  .TestDetails .download-print-section span i {
    color: #ccc;
    padding-right: 5px;
    font-size: 30px;
  }
  .TestDetails .download-print-button {
    background: var(--ncss-white);
    font-weight: bold;
    padding: 0;
    letter-spacing: 0;
    font-family: unset;
  }
  .TestDetails .download-print-button:focus {
    outline: none;
  }
  .TestDetails .contact-section {
    font-size: 16px;
    margin: 15px 0 0 0;
    font-weight: bold;
  }
  .TestDetails .ncss_collapsible {
    background: #eee;
  }
  .TestDetails .Collapsible__contentInner .description {
    color: #000;
    padding-bottom: 5px;
  }
  .TestDetails .Collapsible__trigger {
    cursor: pointer;
  }
  .TestDetails .collapse-p0 {
    padding-left: 0;
    font-size: 1.5rem;
  }
  .TestDetails .ag-theme-balham .ag-header {
    padding: 1px 0;
  }
  .TestDetails .collapse-p0 .ncss-col-sm-1 {
    font-size: 25px;
  }
  .TestDetails .popover-section {
    border: 2px solid #d3d3d3;
    display: grid;
    margin: 0.2rem 4.5rem;
    width: 69%;
    height: 70px;
  }
  .TestDetails .button-hover:hover {
    background: #ccebff;
  }
  .TestDetails .hide-button {
    display: none;
  }
  .TestDetails .popover-trfsection {
    border: 1px solid #d3d3d3;
    display: grid;
    padding: 20px;
    margin: 0.2rem 4.5rem;
    width: 72%;
    background-color: #fff;
    box-shadow: 2px 2px #888;
  }
  .popover-section .button-hover {
    color: #0078b9 !important;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: bold !important;
    border: 1px solid #ddd !important;
    text-decoration: underline;
    padding: 17px;
    font-size: 16px;
  }
  .TestDetails .download-print-button {
    background: #f7f7f7;
  }
  .TestDetails .button-test {
    color: #fff;
    font-size: inherit;
    text-transform: uppercase;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 10px;
  }
  .TestDetails .button-size {
    width: 170px;
    height: 60px;
  }
  .TestDetails .bg-color-grey {
    background: #707070;
  }
  .TestDetails .bg-color-or {
    background: #ea723f;
  }
  .TestDetails .bg-color-bl {
    background: #0078b9;
  }
  .TestDetails .frf-comments-padding {
    padding-left: 15px;
  }
  .TestDetails .qrf-comments-padding {
    padding-left: 25px;
  }
  .TestDetails .ag-body-viewport.ag-layout-normal {
    overflow-y: scroll;
  }
  .TestDetails .download_button {
    color: #333;
    padding-right: 5px;
    font-size: 25px;
    padding: 0px 2px;
    margin-left: 10px;
  }
  .TestDetails .download_button:hover {
    background-color: #ccc;
    color: #fff;
    cursor: pointer;
  }
  .TestDetails .frf-attachment-align {
    padding-left: 15px;
    vertical-align: top;
  }
  .pdf_table.testResults,
  .pdf_table.testResults th,
  .pdf_table.testResults tr,
  .pdf_table.testResults tbody tr {
    border-bottom: 1px solid #000;
  }
  .pdf_table {
    width: 100%;
    border-spacing: 0px;
    border-collapse: collapse;
    border: 1px solid #000;
    color: #000;
  }
  .pdf_table td:first-child {
    font-weight: bold;
  }
  .pdf_table tr.break> td {
    padding-bottom: 20px;
  }
  .pdf_table th,
  .pdf_table td {
    text-align: left;
    padding: 3px;
    overflow-wrap: break-word;
    white-space: pre-line;
  }
  .pdf_table .break_class td {
    padding: 10px;
  }
  .pdf_table .grey-background,
  .pdf_table thead tr:first-child th {
    background: #e5e5e5;
    border-bottom: 1px solid #000;
    text-transform: uppercase;
    font-size: 12px;
  }
  .testDetailsPDF {
    position: absolute;
    left: -9999em;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    font-family: "DejaVu Sans", "Arial", sans-serif;
    font-size: 10px;
  }
  @font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
  }
  .test_details .header {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
  }
  .test_details .header-brand-pdf-tag {
    margin-left: 6%;
  }
  .test_details .header-brand-pdf {
    font-size: 18px;
    text-transform: upperCase;
  }
  .test_details .pdf_header {
    display: table;
    width: 100%;
    text-align: center;
    vertical-align: top;
  }
  .test_details .pdf_header span {
    display: table-cell;
    padding: 0;
  }
  .test_details .pdf_header .g72-converse {
    margin-bottom: 20px;
    margin-right: 0;
    padding: 0;
    margin: 0;
  }
  .test_details .pdf_header .g72-swoosh,
  .test_details .pdf_header .g72-hurley {
    font-size: 26px;
    display: inherit;
  }
  .test_details .pdf_header .g72-jordan {
    font-size: 41px;
    padding-left: 40px;
  }
  .test_details .pdf_header i,
  .test_details .footer * {
    color: #707070;
  }
  .test_details .footer * {
    font-size: 12px;
  }
  .test_details .hurley_span {
    text-align: right;
    width: 100%;
    float: left;
  }
  .remove-border {
    border: none !important;
  }
  .add-border-right {
    border-right: solid 1px #000 !important;
  }
  .add-border-top {
    border-top: solid 1px #000 !important;
  }
  .add-border-left {
    border-left: solid 1px #000 !important;
  }
  .add-border-bottom {
    border-bottom: solid 1px #000 !important;
  }
  .ico {
    display: block;
    height: 40px; /*height of icon */
    width: 40px; /*width of icon */
    position: relative;
    left: 0;
  }
  .ico.g72-snkrs {
    top: -50px;
  }
  .ico.g72-converse {
    top: -20px;
  }
  .ico.g72-hurley {
    top: -20px;
  }
  .adjust-icon {
    position: relative;
  }
  .adjust-icon.g72-swoosh {
    bottom: 16px;
  }
  .adjust-icon.g72-jordan {
    bottom: -3px;
  }
  .adjust-icon.g72-hurley {
    bottom: -1px;
  }
  .vertical-top-dashboard {
    vertical-align: top;
  }
  