.sub-heading {
    font-size: 25px;
    padding-left: 8px;
  }
  .attachment {
    font-size: 25px;
    margin-bottom: 0.5rem;
  }
  .description {
    color: var(--ncss-grey);
  }
  .custom-no-row {
    padding: 10px;
    color: #f00;
    font-size: 15px;
  }
  .error_msg {
    color: #f00;
  }
  .button-trf {
    border: 2px solid var(--ncss-grey)-md;
    border-radius: 4px;
    padding: 0.4rem 1rem;
    margin-bottom: 1rem;
    color: var(--ncss-grey);
  }
  .button-lab {
    border: 2px solid var(--ncss-grey)-md;
    border-radius: 4px;
    padding: 0.4rem 1.7rem;
    color: var(--ncss-grey);
  }
  .lab-complete {
    margin: 150px auto 20px auto;
    padding: 0 90px;
  }
  .upload {
    float: right;
    margin-right: -72px;
    margin-top: -22px;
    background: #f7f7f7;
  }
  .download {
    margin-top: -22px;
    float: right;
    margin-right: -10px;
    background: none;
  }
  .download-section {
    font-size: 14px;
    letter-spacing: 3px;
    background: #f7f7f7;
    font-weight: bold;
  }
  .download-section i {
    color: #ccc;
    padding-right: 5px;
    font-size: 30px;
  }
  .download-button {
    background: #f7f7f7;
    font-weight: bold;
    padding: 0;
    letter-spacing: 0;
    font-family: unset;
  }
  .download-button:focus {
    outline: none;
  }
  .labrow {
    margin-left: -42px;
    margin-right: -18px;
  }
  .new-material {
    color: var(--ncss-white);
    padding: 0.4rem 2.5rem;
    margin-right: 10px;
    margin-left: 20px;
  }
  .new-material-add-section {
    text-align: right;
  }
  .bottom-section {
    text-align: center;
  }
  .astericks {
    color: #f00;
  }
  .secondary-button {
    background: var(--ncss-accent);
    padding: 0.4rem 2.5rem;
    border: 1px solid var(--ncss-accent);
    margin-right: 10px;
    color: var(--ncss-white);
  }
  .secondary-button-addlocation {
    background: var(--ncss-grey);
    padding: 0.4rem 2.5rem;
    border: 1px solid var(--ncss-grey);
    margin-right: 10px;
    color: var(--ncss-white);
  }
  .button-width-adjust {
    width: 8rem;
  }
  .progresstracker-next-button {
    background: var(--ncss-accent);
    padding: 0.4rem 2.5rem;
    float: right;
    border: 1px solid var(--ncss-accent);
    margin-right: 10px;
    color: var(--ncss-white);
  }
  .progresstracker-prevoius-button {
    background: var(--ncss-accent);
    padding: 0.4rem 2.5rem;
    float: left;
    border: 1px solid var(--ncss-accent);
    margin-right: 10px;
    color: var(--ncss-white);
  }
  .popup-bottom {
    float: right;
  }
  .no-button {
    padding: 0.4rem 1.5rem;
    border: 1px solid var(--ncss-grey);
    margin-right: 10px;
    color: var(--ncss-white);
  }
  .submit-button {
    background: var(--ncss-grey)-button;
    padding: 0.4rem 2.5rem;
    color: var(--ncss-grey);
    margin-right: 10px;
    border: 1px solid var(--ncss-grey);
  }
  .submit-button-enable {
    background: var(--ncss-black);
    padding: 0.4rem 2.5rem;
    color: var(--ncss-white);
    margin-right: 10px;
    border: 1px solid #000;
  }
  .trf-button-selected:focus {
    outline: 0;
  }
  .trf-icon-brand-display {
    display: inline-flex;
    display: -ms-inline-flexbox;
    vertical-align: middle;
  }
  .nike-icon {
    font-size: 3.2rem;
  }
  .converse-icon {
    font-size: 1.4rem;
  }
  .hurley-icon {
    font-size: 2rem;
  }
  .icon-align-center {
    align-self: center;
  }
  .radio-sub-info {
    font-size: 13px;
  }
  .inline-flex-display {
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: inline-flex;
  }
  .mr3-display-inline {
    margin-right: 3rem;
  }
  .flex-head-center {
    align-self: center;
  }
  .testpackage-font-adjust {
    font-size: 16px !important;
  }
  .grid-filter-textbox {
    border: 4px solid #3498db;
    width: 100%;
    height: 3rem;
    padding: 15px;
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }
  .grid-filter-p-right-10 {
    padding-right: 10px;
  }
  .p2-top-sm {
    padding-top: 8px;
  }
  .p2-bottom-sm {
    padding-bottom: 8px;
  }
  .p2-left-sm {
    padding-left: 8px;
  }
  .p4-top-sm {
    padding-top: 16px;
  }
  .p4-bottom-sm {
    padding-bottom: 16px;
  }
  .pl0-sm {
    padding-left: 0px;
  }
  .trf-data-text {
    font-size: 16px;
    font-weight: 700;
  }
  .mat-data-text {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    padding: 0px 10px;
    background: #ddd;
    background: #cfcfcf;
  }
  .header-text-mat {
    font-size: 16px;
    padding-left: 0px;
  }
  .trf-data-text-location {
    font-size: 20px;
    padding-left: 0;
  }
  .ncss-textarea-container {
    font-size: 16px;
    font-weight: 700;
  }
  .trf-extra-top-margin {
    margin-top: 1rem;
  }
  .text-left {
    text-align: left !important;
  }
  .review-icon {
    font-size: 1.2rem;
  }
  .review-icon-nike {
    font-size: 2.5rem;
  }
  .error-text {
    color: var(--ncss-error);
    text-align: center;
    font-weight: 550;
  }
  .material-error-section {
    border: 3px solid #f7f7f7;
    padding: 10px;
  }
  .error-text-material {
    color: var(--ncss-error);
  }
  .error-text-not {
    color: #707070;
    font-weight: bold;
    padding-left: 0px;
  }
  .error-text-not-ink {
    color: #707070;
    font-weight: bold;
    padding-left: 0px;
  }
  .error-hide {
    display: none;
  }
  .popover-trfsection {
    border: 1px solid #d3d3d3;
    display: grid;
    padding: 20px;
    margin: 0.2rem 4.5rem;
    width: 69%;
    background-color: #fff;
    box-shadow: 2px 2px #888;
  }
  .react-tiny-popover-container {
    z-index: 100;
  }
  .popover-new-materialsection {
    border: 2px solid #d3d3d3;
    display: grid;
    padding: 10px;
    margin: 0.2rem 6.2rem;
    width: 80%;
    background-color: #fff;
    box-shadow: -2px -2px 2px #888;
  }
  .popover-button {
    position: absolute;
    right: 0;
    margin-right: 7rem;
    background: transparent;
    color: #ff4500;
  }
  .popover-button-new-materialsection {
    position: absolute;
    right: 0;
    margin-right: 4rem;
    background: transparent;
    color: #ff4500;
  }
  .popover-button-licensee-sec {
    position: absolute;
    right: 0;
    margin-right: 14rem;
    background: transparent;
    color: #ff4500;
  }
  .color-trf {
    background: transparent;
    padding: 0px;
  }
  .color-trf i {
    color: #ff4500;
    font-size: 14px;
  }
  .prevent-scroll {
    position: fixed;
    padding: 5px 60px;
    z-index: 13;
    width: 100%;
    margin: 0px;
    overflow: hidden;
  }
  .top-scroll-fix {
    top: 44px;
    background: #f7f7f7;
  }
  .bottom-scroll-fix {
    bottom: 61px;
  }
  .clear-margin {
    margin-bottom: 0 !important;
  }
  .clear-button {
    font-size: 15px;
    background-color: #fff;
    color: #0078b9 !important;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: bold !important;
  /* border-radius: 5px; */
  /* text-decoration: underline; */
    border: 1px solid #000;
  }
  .trf-review {
    margin-bottom: 88px;
  }
  .wrap {
    display: block;
    max-width: 300px;
  }
  .material-type-section {
    padding: 10px 0px;
    margin: -1px;
    box-shadow: 5px 5px 5px #b1acac;
    width: 98%;
    padding-top: 0px;
    border: 3px solid rgba(0,0,0,0.4);
  }
  .additional-test-mandatory {
    background-color: #f7f7f7;
    padding: 5px 8px;
  }
  .additional-test-mandatory-message {
    background-color: #f7f7f7;
  }
  .top-border {
    border-top: 2px solid rgba(0,0,0,0.4);
  }
  .variant-box {
    width: 100%;
    border: 5px solid #808080;
    position: relative;
    display: table;
    padding: 5px;
    margin: 0px 1px 6px 1px;
    border-radius: 10px;
    background: #dedede;
    color: #000;
  }
  .small-text {
    font-size: 15px !important;
  }
  .in-line {
    display: inline;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .prevent-scroll {
      position: fixed;
      padding: 0px 60px;
      z-index: 11;
      width: 100%;
      margin: 0px;
    }
    .progress-tracker-align {
      margin-top: 95px;
      margin-bottom: 103px !important;
    }
  }
  .print-area {
    margin-left: 50px;
    margin-right: 50px;
    padding-top: 50px;
  }
  .header-brand-pdf {
    left: 1rem;
    top: 1rem;
    margin-bottom: 2rem;
    font-size: 38px;
    font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .disclaimer {
    background: #ddd;
    padding: 10px;
    border: 2px solid #000;
  }
  .disclaimer h5 {
    font-weight: bold;
  }
  .pdf-header {
    background: #ddd;
    border: 2px solid #000;
  }
  .pdf-header .menu-logo {
    padding-left: 5px;
    padding-right: 5px;
  }
  .trf-content {
    border: 2px solid #000;
  }
  .trf-content .p2-sm,
  .trf-content .pl0-sm {
    font-size: 15px;
  }
  .menu-logo-nike {
    margin-left: 67%;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 22px;
    vertical-align: baseline;
  }
  .free-text {
    border: 1px solid #ddd;
    padding: 12px;
    padding-top: 20px;
  }
  .supplier-info {
    padding: 20px;
  }
  .space {
    padding-left: 30px !important;
  }
  .qrf-content {
    padding: 8px;
    border: 2px;
  }
  .qrf-content .p2-sm,
  .qrf-content .pl0-sm {
    font-size: 15px;
  }
  .ink-text {
    padding-left: 0px;
  }
  .ink-label {
    padding-left: 0px;
  }
  .supplier-info-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    padding-bottom: 8px;
  }
  .vendor-checkbox {
    width: 10%;
  }
  .vendor-checkbox-adm {
    width: 6%;
  }
  .vendor-background {
    font-weight: bold;
    background: #ffe203;
    padding: 3px 5px 3px 3px;
    border: 1px solid #ffe203;
    color: #000;
    width: max-content;
  }
  .vendor-background.address {
    max-width: 100%;
  }
  .max-width {
    width: 70%;
  }
  .button-width {
    width: 33%;
  }
  .popover-option {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }
  .popover-option .button-hover {
    color: #0078b9 !important;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: bold !important;
    border: 1px solid #ddd !important;
    padding: 1px;
    font-size: 15px;
    width: 100%;
  }
  .float-right {
    float: right;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 10px 0px 10px;
  }
  .adj-padding {
    padding-left: 100px;
    padding-right: 25px;
    width: fit-content;
  }
  .adj-padding-vendor {
    padding-left: 40px;
  }
  .row-adm {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .radio-input {
    width: 20px;
    margin-right: 36px;
  }
  .vendor-type-label {
    width: 132px;
  }
  .user_section {
    color: #0078b9;
    font-size: 18px;
  }

  .eds-custom-input-with-search {
    margin: 0 !important;
    width: 100%;
    max-width: 600px;
  }

  div.input-with-search > div:first-child {
    width: 100%;
    max-width: 600px;
  }

  .vertical-center-align {
    display: flex;
    align-items: baseline;
  }

  #react-select-2-listbox {
    z-index: 999999;
  }

  .async-fix-height {
    height: 350px;
  }

  .chip-wrapper {
    display: flex;
    align-items: center;
  }

  .text-red {
    color: #f00;
  }