.add-material-modal-container {
  width: 90% !important;
  top: 40% !important;
}
.add-material-modal-container .variant-box {
  width: 325%;
}
.pcx-attribute {
  vertical-align: top;
  padding-top: 3.4rem;
}

.vendor-group-toggle{
    font-size: 16px;
    width: auto;
}
.dropdown-pcx {
  display: table;
  position: relative;
}
.new-material:disabled {
  border: 1px solid #999;
  background-color: #ccc;
  color: #666;
  margin-right: 10px;
  margin-left: 20px;
}
.add-supplier-text {
  font-size: 16px;
  color: #000;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}
.no-padding-supplier {
  padding-left: 0px;
}
.cursor-not-allowed {
  cursor: not-allowed;
  background: #ddd;
  padding-bottom: 1rem;
}
