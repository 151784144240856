@require 'variables'
ol.progtrckr {
  list-style-type: none;
  padding-left: 11%;
  text-align: center;
  counter-reset: 0;
  font-weight: 900;
  font-size: 1.5rem;
  z-index: 11;
}
@media screen and (max-width: 1024px) and (min-width: 771px) {
  ol.progtrckr {
    padding-left: 3rem;
  }
}
@media screen and (max-width: 770px) {
  ol.progtrckr {
    text-align: left;
    padding-left: 3rem;
  }
}
.stepper {
  width: 100%;
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
.progtrckr-steps {
  height: 10rem;
  width: 100%;
  margin: 1rem auto 0 auto;
  overflow: visible;
  background-color: #f7f7f7;
  flex: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.progtrckr-component {
  padding: 1rem 4.5rem 8rem 4.5rem;
  flex: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: #f7f7f7;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .progtrckr-steps {
    height: 10rem;
    z-index: 3 !important;
    position: fixed;
    width: 100%;
    margin: 0 auto;
    overflow: visible;
    background-color: #f7f7f7 !important;
    margin-bottom: 10rem !important;
  }
  .stepper {
    padding-bottom: 130px !important;
    top: 24px !important;
  }
  .progtrckr-component {
    padding: 1rem 4.5rem 0.5rem 4.5rem;
    position: relative;
    top: 10rem;
    background: #f7f7f7;
  }
}
@supports (-ms-ime-align: auto) {
  .progtrckr-component {
    padding: 1rem 4.5rem 0.5rem 4.5rem;
  }
}
ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}
ol.progtrckr li span {
  padding: 0 0.2rem;
  position: relative;
  top: 5rem;
  left: -7.3rem;
  font-size: 14px;
  display: -moz-inline-grid;
  display: -ms-inline-grid;
  display: inline-grid;
  -ms-grid-columns: 150px 20px auto 20px 200px;
  -ms-grid-rows: auto 1fr;
  line-height: 1.4rem;
  width: 11rem;
  height: 1rem;
  pointer-events: none;
}
@media screen and (max-width: 1024px) and (min-width: 771px) {
  ol.progtrckr li span {
    left: -2.3rem;
    width: 5rem;
    pointer-events: none;
  }
}
@media screen and (max-width: 1024px) {
  ol.progtrckr li span {
    left: -4.3rem;
    width: 4rem;
    pointer-events: none;
  }
}
@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}
@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}
ol.progtrckr li.progtrckr-todo {
  color: var(--ncss-grey);
  border-bottom: 4px solid var(-ncss-grey-light);
}
ol.progtrckr li.progtrckr-todo:last-child {
  border-bottom: 0px;
}
@media (max-width: 770px) {
  ol.progtrckr li.progtrckr-todo:last-child {
    width: 0;
  }
}
ol.progtrckr li.progtrckr-doing {
  color: var(--ncss-accent);
  border-bottom: 4px solid #ccc;
}
ol.progtrckr li.progtrckr-doing:last-child {
  border-bottom: 0px;
}
@media (max-width: 770px) {
  ol.progtrckr li.progtrckr-doing:last-child {
    width: 0;
  }
}
ol.progtrckr li.progtrckr-done {
  color: var(--ncss-grey);
  border-bottom: 4px solid var(--ncss-accent);
}
ol.progtrckr li:before {
  position: relative;
  bottom: -2.9rem;
  counter-increment: my-sec-counter;
  content: counter(my-sec-counter);
  float: left;
  left: -1%;
  width: 3.5rem;
  border: 1px solid var(--ncss-grey);
}
ol.progtrckr li.progtrckr-todo:before {
  color: #c0c0c0;
  background-color: #fff;
  padding: 10px;
  line-height: 1.4em;
  border-radius: 50%;
}
ol.progtrckr li.progtrckr-todo:hover:before {
  color: #34495e;
}
ol.progtrckr li.progtrckr-doing:before {
  color: #fff;
  background-color: var(--ncss-accent);
  border: 1px solid var(--ncss-accent);
  padding: 10px;
  line-height: 1.2em;
  border-radius: 50%;
}
ol.progtrckr li.progtrckr-doing:hover:before {
  color: #34495e;
}
ol.progtrckr li.progtrckr-todo:last-child {
  border-bottom: 0px;
}
ol.progtrckr li.progtrckr-done:before {
  color: #fff;
  background-color: var(--ncss-grey);
  padding: 10px;
  line-height: 1.2em;
  border-radius: 50%;
}
.ol.progtrckr li.progtrckr-error:before {
  color: #fff;
  background-color: ncss-error !important;
  padding: 10px;
  line-height: 1.2em;
  border-radius: 50%;
}
.ol.progtrckr li.progtrckr-error {
  color: var(--ncss-grey);
  border-bottom: 4px solid var(--ncss-accent);
}
.stepper-error-doing:before {
  background-color: ncss-orange !important;
}
.stepper-error-doing {
  color: var(--ncss-grey);
  border-bottom: 4px solid var(--ncss-accent);
}
.stepper-error-done:before {
  background-color: #f0b27a !important;
}
.stepper-completed:before {
  color: #fff !important;
  background-color: ncss-grey !important;
}
.stepper-error-done {
  color: var(--ncss-grey);
  border-bottom: 4px solid var(--ncss-accent);
}
.stepper-error-done {
  color: var(--ncss-grey);
  border-bottom: 4px solid var(--ncss-accent);
}
.stepper-completed {
  color: #fff;
  border-bottom: 1px solid var(--ncss-accent);
}
.navigation-buttons {
  padding: 0.01rem;
}
.previous-button {
  width: 10%;
  position: absolute;
  top: 3.5rem;
  left: 6.5rem;
  border-radius: 50%;
  font-size: 40px;
  height: 60px;
  background-color: var(--ncss-white);
  color: var(--ncss-grey);
}
.previous-button:hover {
  color: var(--ncss-accent);
}
.next-button {
  width: 10%;
  position: fixed;
  bottom: 5.8rem;
  right: 6.5rem;
  z-index: 15;
  border-radius: 50%;
  font-size: 40px;
  height: 60px;
  background-color: var(--ncss-white);
  color: var(--ncss-grey);
}
.next-button:hover {
  color: var(--ncss-accent);
}
ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}
.step-progress {
  margin: 1rem 0rem;
}
.disabled {
  pointer-events: none;
}
