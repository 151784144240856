/* nav-font-color = #fff
menu-font-color = #000
nav-height = 68px

nav-logo-sm-size = 30px
nav-logo-lg-size = 22px

nav-right-margin = 0 */
.nav-header-wrapper {
  width: 100%;
  background: var(--nav-bg-color);
  color: #fff;
  height: 68px;
  box-shadow: var(--box-shadow-divider);
}
.nav-header-container {
  display: flex;
  width: 100%;
}
.nav-header-container a {
  color: #fff;
}
.nav-header-title {
  flex: 1 1 auto;
  padding-top: 8px;
}
.nav-header-title span {
  font-size: 2rem;
}
@media screen and (min-width: 641px) {
  .nav-header-title span {
    margin-left: 70px;
  }
}
.nav-header-title i {
  position: absolute;
  font-size: 22px;
  margin-top: 12px;
}
.nav-header-block {
  flex: 0 0 auto;
}
.nav-header-block:last-child {
  margin-right: 0;
}
.nav-header-block .nav-header-menu li {
  height: 68px;
  margin-right: 1rem;
  display: inline-block;
}
.nav-header-block .nav-header-menu li a,
.nav-header-block .nav-header-menu li span {
  padding: 0 0.5rem;
  font-size: 1.3rem;
  font-weight: 700;
  vertical-align: middle;
  line-height: 68px;
  display: inline-block;
  height: 68px;
}
.nav-header-block .nav-header-menu li a.active,
.nav-header-block .nav-header-menu li span.active {
  border-bottom: 3px solid var(--ncss-accent);
  color: var(--ncss-accent);
}
