/* @require 'variables' */
.footer-container {
    background-color: var(--nav-bg-color);
    color: var(--ncss-white);
    padding: 0.7rem 0;
  }
  .footer-container a {
    color: var(--ncss-white);
  }
  .footer-copyright {
    display: block;
    float: right;
  }
  .footer-margin-adjust {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 130px;
  }
  .vertical-line {
    border-left: 3px solid var(--ncss-white);
    margin: 15px;
  }
  .footer-hr {
    border-color: var(--ncss-grey);
  }
  .page-align-items {
    flex: 1 0 auto;
    margin: 60px 80px;
  }
  .page-align-dashboard {
    flex: 1 0 auto;
    margin: 40px 40px 96px 40px;
  }
  .page-align-fixed-items {
    overflow: hidden;
    padding: 0px 0px 0px 0px !important;
  }
  .version-styl {
    color: #808080 !important;
    padding-left: 75% !important;
  }
  .menu-logo-footer {
    font-size: 8px !important;
    vertical-align: baseline;
  }
  