:root {
    --background-color : rgb(241, 241, 241);
    --ncss-accent : #3498db;
    --ncss-success : #48BD1F;
    --ncss-error : #FE0000;
    --ncss-warn : rgb(238, 180, 80);
    --ncss-black : #111;
    --ncss-white : #fff;
    --ncss-offwhite : #F7F7F7;
    --ncss-grey : #707070;
    --ncss-orange : rgb(255, 90, 0) ;
    --ncss-grey-md : #DDDDDD;
    --ncss-grey-light : #E5E5E5;
    --ncss-grey-button : #CCCCCC;
    --max-break-md : 641px;
    --max-break-lg : 1025px;
    --max-break-xl : 1281px;
    --max-break-xxl : 1441px;
    --min-break-md : 640px;
    --min-break-lg : 1024px;
    --min-break-xl : 1280px;
    --min-break-xxl : 1440px;
    --ncss-link-color : #3498db;
    --nav-bg-color : var(--ncss-black);
    --border-divide : 4px solid var(--ncss-grey-md);
    --box-shadow-divider : 0 0 5px rgba(0,0,0,0.5);
    --box-shadow-float : 5px 5px 5px rgba(0,0,0,0.1);
    --btn-border-radius : 4px;
    --card-padding : 1rem;
}