.rc-tooltip-inner {
  background-color: #222;
}
.rc-tooltip {
  font-size: 12px;
  max-width: 40%;
}
.tip {
  position: absolute;
  width: 100px;
  color: #fff;
  background: ncss-grey;
  height: 30px;
  line-height: 30px;
  text-align: center;
  visibility: hidden;
  font-weight: 900;
  border-radius: 15px;
}
.next-button:hover + .tip-next,
.tip-next:hover {
  visibility: visible;
  opacity: 0.8;
  top: 6.5rem;
  right: 4.5rem;
  z-index: 999;
}
.previous-button:hover + .tip-previous,
.tip-previous:hover {
  visibility: visible;
  opacity: 0.8;
  top: 6.5rem;
  left: 4.5rem;
  z-index: 999;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #707070;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
/* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.tooltip:hover .tooltiptext {
  position: absolute;
  top: -35px;
  left: -35px;
  visibility: visible;
}
/* Upload grid style */
.fa.fa-check {
  color: #008000;
}
.fa.fa-times {
  color: #f00;
}
.spinner-upload {
  font-size: 23px !important;
  color: rgba(52,152,219,0.9);
}
.custom-tooltip {
  position: absolute;
  border: 1px solid #6495ed;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
  background: #e0e0e0;
  font-weight: bold;
}
.validation-msg {
  border: 2px solid #fa8072;
  padding: 10px;
}
.field-text {
  padding-bottom: 8px;
  padding-top: 8px;
  font-size: 20px;
  color: #707070;
  font-weight: 700;
}
.field-text-admin {
  padding-bottom: 2px;
  font-size: 20px;
  color: #707070;
  font-weight: 700;
}
.dropdown-text {
  font-size: 16px;
  font-weight: 550;
}
.float-left {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 10px 0px 10px;
}
.download-btn {
  float: right;
  font-weight: 700;
  background: rgba(52,152,219,0.9);
  color: #fff;
  font-size: 17px;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 4px 10px 4px 10px;
  margin: 0px 50px 0px 10px;
}
.download-btn-grid {
  float: right;
  font-weight: 700;
  background: rgba(52,152,219,0.9);
  color: #fff;
  font-size: 14px;
  font-family: "Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 10px 0px 10px;
}
.row-page {
  margin: 20px 0px 20px 0px;
}
.background-text {
  padding: 5px 15px 5px 15px;
  box-shadow: 5px 5px 5px #b1acac;
  overflow: visible;
  background-image: url("nike.png");
  height: 200px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
.upload-text {
  padding: 7px 0 7px 0;
  background: #ddd;
}
.field-padding {
  padding-left: 50px;
  padding-right: 25px;
  width: fit-content;
}
.full-width-details {
  float: left;
  padding: 5px;
  margin: 5px;
  width: 150px;
}
.full-width-grid {
  margin-left: 10px;
  padding-top: 25px;
  display: block;
  height: 150px;
}
.pointer-class {
  cursor: pointer;
}
.master-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.master-text {
  padding-left: 3px;
  width: 100%;
  display: block;
  font-weight: bold;
  font-size: 14px;
  color: #707070;
  border-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.header-text {
  background-color: #b4c6e7;
}
.header-text-sub .ag-header-cell-text {
  color: #c65911;
}
.header-text-con .ag-header-cell-text {
  color: #305496;
}
.header-text-uom .ag-header-cell-text {
  color: #92d050;
}
.header-text-color .ag-header-cell-text {
  color: #ffc000;
}
.ag-row-border-color {
  border-top: var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width);
  border-color: #ff0 !important;
}
.fa-file-excel {
  color: #008000;
}
.filename {
  padding: 5px;
  color: #3498db;
  font-size: 16px;
}
.adj-padding-upload {
  margin-right: 30px;
}
.adj-padding-button {
  margin-left: 153px;
}
.adj-margin {
  float: right;
  padding-left: 10px;
  padding-right: 10px;
  margin: 23px 10px 0px 10px;
}
.adj-margin-admin {
  float: right;
  padding-left: 10px;
  padding-right: 10px;
}
